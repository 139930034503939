export const fetchUserApi = async () => {
  const apiLink = 'https://ipinfo.io?token=66001974f83615'

  try {
    const response = await fetch(apiLink)
    const data = await response.json()
    if (data) {
      return {
        ip: data.ip,
        hostname: data.hostname,
        city: data.city,
        region: data.region,
        country: data.country,
        loc: data.loc,
        org: data.org,
        postal: data.postal,
        timezone: data.timezone,
      }
    } else return null
  } catch (error) {
    console.error('Something wrong, please try again', error)
  }
}
