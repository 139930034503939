import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useReducer,
} from 'react'

import type { IAction } from './model/action-types'

import { initialState } from './model/initial-state'
import { reducer } from './reducer'

const AppContext = createContext<{
  store: typeof initialState
  dispatch: Dispatch<IAction>
}>({
  store: initialState,
  dispatch: () => {},
})

export const AppContextProvider = ({ children }: PropsWithChildren) => {
  const [store, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={{ store, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

AppContext.displayName = "App context";

export default AppContext
