import { useEffect, useState } from 'react'

import { isScreenWidth } from 'shared/utils/functions'

export const useIsMobileScreen = (width: number) => {
  const initialScreenWidth = isScreenWidth(width)
  const [isMobile, setIsMobile] = useState<boolean>(initialScreenWidth || false)

  const handleResize = () => {
    if (isScreenWidth(width)) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
