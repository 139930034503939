import { ROUTES } from 'shared/config/routes'
;(function () {
  const pathname = window.location.pathname
  const isThankPage = pathname === ROUTES.THANK

  if (isThankPage) {
    const htmlElement = document.querySelector('html')
    htmlElement.classList.remove('html_home')

    $('input[type="checkbox"]').on('change', function () {
      $('input[type="checkbox"]').not(this).prop('checked', false)
    })
  }
})()
