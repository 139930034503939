import {
  ISearchParameters,
  IUser,
  IUserApi,
  IUserAccepted,
  IUserInfo,
  IUserQuestions,
} from 'entities/user/model/user'
import type { IAppModals } from '../model/app-store'
import type { IAction } from '../model/action-types'

export const setIsMobile = (payload: boolean): IAction => ({
  type: 'IS_MOBILE',
  payload,
})
export const setIsMobileSmall = (payload: boolean): IAction => ({
  type: 'IS_MOBILE_SMALL',
  payload,
})
export const setIsMobile500 = (payload: boolean): IAction => ({
  type: 'IS_MOBILE_500',
  payload,
})
export const setModals = (payload: IAppModals): IAction => ({
  type: 'MODALS',
  payload,
})
export const setIsCookiesModal = (payload: boolean): IAction => ({
  type: 'IS_COOKIES_MODAL',
  payload,
})
export const setIsAcceptCookiesModal = (payload: boolean): IAction => ({
  type: 'IS_ACCEPT_COOKIES_MODAL',
  payload,
})
export const setIsPrivacyPolicyModal = (payload: boolean): IAction => ({
  type: 'IS_PRIVACY_POLICY_MODAL',
  payload,
})
export const setDocumentModalType = (payload: string | null): IAction => ({
  type: 'DOCUMENT_MODAL_TYPE',
  payload,
})
export const setIsAffirmationModal = (payload: boolean): IAction => ({
  type: 'IS_AFFIRMATION_MODAL',
  payload,
})
export const setIsSuccessAffirmationModal = (payload: boolean): IAction => ({
  type: 'IS_SUCCESS_AFFIRMATION_MODAL',
  payload,
})
export const setUser = (payload: IUser): IAction => ({
  type: 'USER',
  payload,
})
export const setUserApi = (payload: IUserApi): IAction => ({
  type: 'USER_API',
  payload,
})
export const setUserSearchParams = (payload: ISearchParameters): IAction => ({
  type: 'USER_SEARCH_PARAMS',
  payload,
})
export const setUserAccepted = (payload: IUserAccepted): IAction => ({
  type: 'USER_ACCEPTED',
  payload,
})
export const setUserInfo = (payload: IUserInfo): IAction => ({
  type: 'USER_INFO',
  payload,
})
export const setUserQuestions = (payload: IUserQuestions): IAction => ({
  type: 'USER_QUESTIONS',
  payload,
})
