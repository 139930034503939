import { useEffect } from 'react'

import useAppContext from 'app/store/useAppContext'
import { AffirmationForm } from 'features/affirmation/form/form'
import LogoImg from 'shared/assets/images/logo.png'

import './HomePage.css'
import '../lib/helpers/homeLogic'

export const HomePage = () => {
  const answers = sessionStorage.getItem('quiz-data')

  useEffect(() => {
    if (answers) {
      sessionStorage.removeItem("quiz-data");
    }

    const htmlElement = document.querySelector('html')
    htmlElement.classList.add('html_home')
  }, [])

  const {
    store: { isMobile500, user },
    dispatch,
  } = useAppContext()

  return (
    <>
      <div className="body" style={{transform: 'translateY(0px)'}}>
        <div className="content">
          <div className="header">
            <div className="header__body">
              <div className="header__name">
                <div className="header__avatar">
                  <img src={LogoImg} alt="LogoImg" />
                </div>
                <div className="header__desc">
                  <p className="header__desc-name">WhatsApp Bot</p>
                  <p>
                    Зарабатывайте до $10 000 в месяц на уникальной платформе
                    WhatsApp
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="register-form">
            <div className="loader" style={{ textAlign: 'center' }}>
              <div className="cssload-thecube">
                <div className="cssload-cube cssload-c1"></div>
                <div className="cssload-cube cssload-c2"></div>
                <div className="cssload-cube cssload-c4"></div>
                <div className="cssload-cube cssload-c3"></div>
              </div>
              <div style={{
                fontWeight: 'bold',
                paddingTop: '34px',
                position: 'relative',
                textAlign: 'center',
                color: 'white',
              }}>
                Przetwarzanie danych
              </div>
            </div>
            <div className="chat">
              <div className="chat-content-list chat-content-list-1"></div>

              <div className="user--name">
                <div className="form-input-main">
                  <input type="text" className="form-input" name="first_name" required="" placeholder="Imię" />
                </div>

                <div className="form-input-main">
                  <input type="text" className="form-input" name="last_name" required="" placeholder="Nazwisko" />
                </div>

                <div className="chat-content-buttons-gender next--answer-content nameNext">
                  <div className="chat-content-buttons-gender-block">
                    <span className="name--next_btn">Далее</span>
                  </div>
                </div>
              </div>

              <div className="chat-content-list chat-content-list-2"></div>

              <div className="user--email">
                <div className="form-input-main">
                  <input type="email" name="email" placeholder="E-mail" required="" />
                </div>

                <div className="chat-content-buttons-gender next--answer-content nameNext">
                  <div className="chat-content-buttons-gender-block">
                    <span className="email--next_btn">Далее</span>
                  </div>
                </div>
              </div>

              <div className="chat-content-list chat-content-list-3"></div>

              <div className="user--phone">
                <AffirmationForm
                  title="Оставьте Ваши контакты, чтобы открыть инвест-счет"
                  user={user}
                  dispatch={dispatch}
                  isMobile500={isMobile500}
                />
              </div>

              <div className="chat-content-list chat-content-list-4"></div>

              <input type="text" className="inp" id="res" value={0} readOnly={true} />
              <div id="down-box"></div>
            </div>
          </div>

          <div className="hidden_scroll"></div>
        </div>
      </div>
    </>
  )
}
