import { IUserDefaultQuestion, IUserQuestion } from 'entities/user/model/user'

export const replaceQuestions = (): IUserQuestion[] | [] => {
  const answers = sessionStorage.getItem('quiz-data')
  const answersArray: IUserDefaultQuestion[] = answers
    ? JSON.parse(answers)
    : []

  return answersArray?.map((answer) => {
    return {
      question: answer?.quest,
      answer: answer?.answer,
    }
  })
}
