import { useEffect } from 'react'
import useAppContext from 'app/store/useAppContext'

export const useReactPixelMetric = (isUse: boolean) => {
  const {
    store: {
      user: {
        searchParams: { metric },
      },
    },
  } = useAppContext()

  useEffect(() => {
    if (isUse) {
      const ReactPixel = require('react-facebook-pixel')
      ReactPixel.default.init(metric ? metric.toString() : '')
      ReactPixel.default.track('Lead')
    }
  }, [isUse, metric])
}
