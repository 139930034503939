import { ROUTES } from 'shared/config/routes'

(function () {
  const pathname = window.location.pathname

  if (pathname === ROUTES.THANK) return

  setTimeout(() => {
      if (sessionStorage.getItem("quiz-data")) {
        sessionStorage.removeItem("quiz-data");
      }
      const chatContentList1 = document.querySelector(".chat-content-list-1");
      const chatContentList2 = document.querySelector(".chat-content-list-2");
      const chatContentList3 = document.querySelector(".chat-content-list-3");
      const chatContentList4 = document.querySelector(".chat-content-list-4");

      const userNameField = document.querySelector(".user--name");
      const userEmail = document.querySelector(".user--email");
      const userPhone = document.querySelector(".user--phone");

      const getCurrentTime = () => {
        const date = new Date();
        const hours =
          date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        const minutes =
          date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        let currentTime = `${hours}:${minutes}`;
        return currentTime;
      };

      const massange = [
        {
          m: `Привет! Мы рады приветствовать вас на официальной платформе по заработку в Whatsapp Business! Я автоматический робот, созданный Марком Цукербергом для работы на финансовом рынке!  `,
        },
        {
          m: `Моя задача - анализировать финансовый рынок и совершать прибыльные сделки с акциями глобальных компаний. Это позволяет вам получать стабильный доход в размере 500 долларов в день без опыта!  `,
        },
        {
          m: `Могу ли я рассчитать ваш средний ежедневный доход? `,
        },
        {
          m: `Doskonale! Czy możesz mi podać swoje imię, jak możemy się do ciebie zwrócić? `,
        },
        {
          m: `Dziękuje! Przejdziemy do następnego pytania. `,
        },
        {
          m: `Вы удовлетворены вашим уровнем дохода? `,
        },
        {
          m: `Сколько часов в день вы тратите на работу? `,
        },
        {
          m: `У вас есть источник пассивного дохода? `,
        },
        {
          m: `Doskonale! Zanim przejdziemy do następnego pytania, chcemy Tobie powiedzieć: dla nas jest bardzo ważne bezpieczeństwo, więc musimy mieć pewność, że odpowiada realny człowiek. Wpisz swój adres e-mail w celu szybkiej weryfikacji. `,
        },
        {
          m: `Dziękuje! Weryfikacja przeszła pomyślnie. `,
        },
        {
          m: `Независимо от того, есть у вас опыт пассивного дохода или нет, я предоставляю вам возможность получать пассивный доход с использованием моего искусственного интеллекта без каких-либо знаний и опыта! `,
        },
        {
          m: `Перейдем к следующему вопросу.`,
        },
        {
          m: `Средний месячный доход пользователя - 14 000 долларов. При этом вам нужно тратить 1-2 часа в день, используя программу! `,
        },
        {
          m: `Сколько времени вы готовы уделять программе? `,
        },
        {
          m: `Спасибо за заполнение опроса! Я проанализирую ваши ответы в течение нескольких секунд... `,
        },
        {
          m: `У меня для вас хорошие новости, вы можете заработать на платформе Whatsapp Business. Ваш ожидаемый ежедневный доход - 420 долларов! Но через неделю эта сумма может стать еще больше! `,
        },
        {
          m: `Оставьте ваши контактные данные, если вы готовы заработать эту сумму завтра! В течение дня с вами свяжется личный помощник, чтобы помочь вам активировать ваш личный аккаунт WhatsApp для заработка. `,
        },
        {
          m: `Давайте продолжим наш разговор в телефонном режиме? Мы уверены, что это будет удобнее для вас..`,
        },
        {
          m: `Doskonale! Fachowiec naszej kompanii zwiąże się z wami w najbliższym czasie. `,
        },
      ];

      let choiceArray = [];

      var mass_id = 0,
        length_mass = 0,
        lengt_num_mas = 0,
        text = "",
        // speedtext = 1,
        speedtext = 14,
        process = !0;

    function scrollToBottom() {
      let newHeight = 300

      $('.content')?.animate(
        {
          scrollTop: $('.chat-content-list')?.height() + newHeight,
        },
        700,
      )

      if (lengt_num_mas === 17) {
        $('.content')?.animate(
          {
            scrollTop: $('.chat-content-list')?.height() + -300,
          },
          700,
        )
      }
    }

      const checkFormElementsIsShow = (element) => {
        if (
          !userNameField.classList.contains("show") &&
          !userEmail.classList.contains("show") &&
          !userPhone.classList.contains("show")
        ) {
          chatContentList1.insertAdjacentHTML("beforeend", element);
        }

        if (
          userNameField.classList.contains("show") &&
          !userEmail.classList.contains("show") &&
          !userPhone.classList.contains("show")
        ) {
          chatContentList2.insertAdjacentHTML("beforeend", element);
        }

        if (
          userNameField.classList.contains("show") &&
          userEmail.classList.contains("show") &&
          !userPhone.classList.contains("show")
        ) {
          chatContentList3.insertAdjacentHTML("beforeend", element);
        }

        if (
          userNameField.classList.contains("show") &&
          userEmail.classList.contains("show") &&
          userPhone.classList.contains("show")
        ) {
          chatContentList4.insertAdjacentHTML("beforeend", element);
        }

        scrollToBottom()
      };

      function app() {
        const template = `<div class="chat-content-item manager ">
                <div class="chat-content-desc">
                    <div class="chat-content-desc-item manager">
                        <p class="message-time" id="time">${getCurrentTime()}</p>
                        <p class="text" id="mass${mass_id}"></p>
                    </div>
                </div>
            </div>`;

        checkFormElementsIsShow(template);
      }

      function myMassange(e) {
        let template = `<div class="chat-content-item user item-active">
                  <div class="chat-content-desc">
                      <div class="chat-content-desc-item user">
                          <p class="message-time" id="time">${getCurrentTime()}</p>
                          <p class="text">${e}</p>
                      </div>
                  </div>
              </div>`;

        checkFormElementsIsShow(template);
      }

      function question1() {
        $(".chat-content-list-1").append(
          `<div class="chat-content-buttons-gender">
                <div class="chat-content-buttons-gender-block">
                    <span class="question1M">Хорошо, поехали</span>
                </div>
            </div>`
        );
      }

      function choise1() {
        $(".question1M").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          // choiceArray.push(e.target.textContent);

          setTimeout(() => {
            process = !0;
          }, 500);
        });
      }

      function question3() {
        $(".chat-content-list-2").append(
          `<div class="chat-content-buttons-gender">
                <div class="chat-content-buttons-gender-block">
                    <span class="question3M">Да, я удовлетворен, но хотел бы больше</span>
                </div>
                <div class="chat-content-buttons-gender-block">
                    <span class="question3W">Нет, я не удовлетворен, я хотел бы больше</span>
                </div>
                <div class="chat-content-buttons-gender-block">
                    <span class="question3P">У меня нет дохода</span>
                </div>
            </div>`
        );
      }

      function choise3() {
        $(".question3M").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "Вы удовлетворены вашим уровнем дохода? ",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
        $(".question3W").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "Вы удовлетворены вашим уровнем дохода? ",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
        $(".question3P").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "Вы удовлетворены вашим уровнем дохода? ",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
      }

      function question4() {
        $(".chat-content-list-2").append(
          `<div class="chat-content-buttons-gender">
                <div class="chat-content-buttons-gender-block">
                    <span class="question4M">2-5 часов</span>
                </div>
                <div class="chat-content-buttons-gender-block">
                    <span class="question4W">5-8 часов</span>
                </div>
                <div class="chat-content-buttons-gender-block">
                    <span class="question4P">Больше 8 часов в день</span>
                </div>
                <div class="chat-content-buttons-gender-block">
                    <span class="question4Q">Я не работаю</span>
                </div>
            </div>`
        );
      }

      function choise4() {
        $(".question4M").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "Сколько часов в день вы тратите на работу?",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });

        $(".question4W").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "Сколько часов в день вы тратите на работу?",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
        $(".question4P").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "Сколько часов в день вы тратите на работу?",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
        $(".question4Q").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "Сколько часов в день вы тратите на работу?",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
      }

      function question5() {
        $(".chat-content-list-2").append(
          `<div class="chat-content-buttons-gender">
                <div class="chat-content-buttons-gender-block">
                    <span class="question5M">Да</span>
                </div>
                <div class="chat-content-buttons-gender-block">
                    <span class="question5W">Нет, но хотелось бы</span>
                </div>
            </div>`
        );
      }

      function choise5() {
        $(".question5M").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "У вас есть источник пассивного дохода?",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
        $(".question5W").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "У вас есть источник пассивного дохода?",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
      }

      function question6() {
        $(".chat-content-list-3").append(
          `<div class="chat-content-buttons-gender">
                <div class="chat-content-buttons-gender-block">
                    <span class="question6M">До 1 часа в день</span>
                </div>
                <div class="chat-content-buttons-gender-block">
                    <span class="question6W">До 2 часов в день</span>
                </div>
                <div class="chat-content-buttons-gender-block">
                    <span class="question6P">Любое время</span>
                </div>
            </div>`
        );
      }

      function choise6() {
        $(".question6M").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "Сколько времени вы готовы уделять программе? ",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
        $(".question6W").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "Сколько времени вы готовы уделять программе? ",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
        $(".question6P").click((e) => {
          e.target.parentElement.parentElement.remove();
          myMassange(e.target.textContent);
          choiceArray.push({
            quest: "Сколько времени вы готовы уделять программе? ",
            answer: e.target.textContent,
          });
          sessionStorage.setItem("quiz-data", JSON.stringify(choiceArray));
          setTimeout(() => {
            process = !0;
          }, 500);
        });
      }

      function enterPhone() {
        userPhone.classList.add("show");
      }

      function checkPhoneInputValue() {
        const phone = document.querySelector("input[name=phone]");

        $(".next--phone_btn").click((e) => {
          if (!phone.value) {
            phone.classList.add("error");
            phone.placeholder = "Wprowadź swój numer telefonu";
          } else {
            e.target.parentElement.parentElement.remove();
            // (document.querySelector(".phoneNext").style.display = "none")
            // userPhone.classList.remove('show')
            setTimeout(() => {
              process = !0;
            }, 500);
          }
        });
      }

      const registerButton = `<button name="submitBtn" type="submit">Ok</button>`;

      function showRegisterButton() {
        $(".chat-content-list-4").append(
          `<div class="chat-content-buttons-gender">
                <div class="chat-content-buttons-gender-block">
                    ${registerButton}
                </div>
            </div>`
        );
      }

      setTimeout(() => {
        const template = `<div class="chat-content-item manager ">
                <div class="chat-content-desc">
                    <div class="chat-content-desc-item manager">
                        <p class="message-time" id="time">${getCurrentTime()}</p>
                        <p class="text" id="mass${mass_id}"></p>
                    </div>
                </div>
            </div>`;

        checkFormElementsIsShow(template);

        const n = setInterval(function () {
          if (1 == process)
            if (lengt_num_mas != massange.length) {
              if (
                ((text += massange[lengt_num_mas].m[length_mass]),
                  length_mass++,
                  $("#mass" + lengt_num_mas).html(text),
                2 === lengt_num_mas &&
                length_mass === massange[lengt_num_mas].m.length - 1 &&
                (question1(),
                  (process = !1),
                  choise1(),
                  $("#scroll_id").addClass("hide-q"),
                  $("#scroll_id").removeClass("hide-q")),
                3 === lengt_num_mas &&
                length_mass === massange[lengt_num_mas].m.length - 1,
                5 === lengt_num_mas &&
                length_mass === massange[lengt_num_mas].m.length - 1 &&
                (question3(),
                  (process = !1),
                  choise3(),
                  $("#scroll_id").addClass("hide-q"),
                  $("#scroll_id").removeClass("hide-q")),
                6 === lengt_num_mas &&
                length_mass === massange[lengt_num_mas].m.length - 1 &&
                (question4(),
                  (process = !1),
                  choise4(),
                  $("#scroll_id").addClass("hide-q"),
                  $("#scroll_id").removeClass("hide-q")),
                7 === lengt_num_mas &&
                length_mass === massange[lengt_num_mas].m.length - 1 &&
                (question5(),
                  (process = !1),
                  choise5(),
                  $("#scroll_id").addClass("hide-q"),
                  $("#scroll_id").removeClass("hide-q")),
                8 === lengt_num_mas &&
                length_mass === massange[lengt_num_mas].m.length - 1,
                13 === lengt_num_mas &&
                length_mass === massange[lengt_num_mas].m.length - 1 &&
                (question6(),
                  (process = !1),
                  choise6(),
                  $("#scroll_id").addClass("hide-q"),
                  $("#scroll_id").removeClass("hide-q")),
                17 === lengt_num_mas &&
                length_mass === massange[lengt_num_mas].m.length - 1 &&
                (enterPhone(),
                  (process = !1),
                  checkPhoneInputValue(),
                  $("#scroll_id").addClass("hide-q"),
                  $("#scroll_id").removeClass("hide-q")),
                18 === lengt_num_mas &&
                length_mass === massange[lengt_num_mas].m.length - 1 &&
                (showRegisterButton(),
                  (process = !1),
                  $("#scroll_id").addClass("hide-q"),
                  $("#scroll_id").removeClass("hide-q")),
                length_mass == massange[lengt_num_mas].m.length)
              ) {
                if (lengt_num_mas === 2 || lengt_num_mas === 7) {
                  (lengt_num_mas = lengt_num_mas + 3),
                    (mass_id = mass_id + 3),
                    (length_mass = 0),
                    (text = ""),
                    app();
                } else {
                  lengt_num_mas++, mass_id++, (length_mass = 0), (text = ""), app();
                }
              }
            } else {
              clearInterval(n);
              $("#mass" + lengt_num_mas)
                .parent()
                .parent()
                .css("display", "none");
              $(".iframe-form").addClass("show");
            }
        }, speedtext);
      });

      window.onload = function () {
        document.body.oncontextmenu = function () {
          return false;
        };
        window.addEventListener("selectstart", function (e) {
          e.preventDefault();
        });
        document.addEventListener(
          "keydown",
          function (e) {
            if (
              e.keyCode == 83 &&
              (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
            ) {
              e.preventDefault();
              e.stopPropagation();
            }
          },
          false
        );
      };
  }, 1000)
})()
