import { Outlet } from 'react-router-dom'

import { useSetUserApiData, useSetUserSearchParams } from 'entities/user'
import { useSetIsMobileModal } from 'features/modal'

export const MainLayout = () => {
  useSetIsMobileModal()
  useSetUserSearchParams()
  useSetUserApiData()

  return <Outlet />
}
